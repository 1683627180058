<template>
  <KitItems
    :headline="headline"
    :level="headlineLevel"
    :subline="subline"
    :variants="variants"
  />
</template>

<script setup lang="ts">
  const { getValue, getIngredient } = useAlchemy()

  const props = defineProps<{
    element: AlchemyElement
  }>()

  const headlineLevel = getIngredient<AlchemyIngredientHeadline>(
    props.element,
    "headline",
  )?.level
  const headline = getValue<string>(props.element, "headline")
  const subline = getValue<string>(props.element, "subline")
  const variants: AlchemyIngredientVariant[] = props.element.nestedElements.map(
    ({ ingredients }) => ingredients[0] as AlchemyIngredientVariant,
  )
</script>
