<template>
  <Container>
    <div
      class="flex flex-col gap-8 sm:grid sm:grid-rows-1 shadow py-8 sm:py-8 sm:pl-12 rounded-lg sm:gap-2 sm:grid-cols-[auto,1fr] grid-cols-1"
    >
      <div class="flex flex-col justify-center items-center sm:items-start">
        <component
          :is="`h${level}`"
          class="h3 m-0 mb-2 text-center sm:text-left sm:w-38"
        >
          {{ headline }}
        </component>
        <span class="text-gray-text">
          {{ subline }}
        </span>
        <Button class="w-24 mt-4" size="medium" @click="addToCart">
          Add to Cart
        </Button>
      </div>
      <div class="flex items-center">
        <Slider
          :items="sliderItems"
          slider-type="product"
          :margin="false"
          :extra-columns="2"
          product-layout="blank"
        />
      </div>
    </div>
  </Container>
</template>

<script setup lang="ts">
  import type { SliderItem } from "~/components/Slider/index.vue"
  import { useCartStore } from "~/pinia/cart"
  const { handleError } = useErrorHandling()

  const props = withDefaults(
    defineProps<{
      variants: AlchemyIngredientVariant[]
      headline?: string
      subline?: string
      level?: string
    }>(),
    {
      headline: "",
      level: "2",
      subline: "",
    },
  )

  const loading = ref(false)

  const sliderItems: SliderItem[] = props.variants.map((variant) => ({
    id: variant.id,
    imageUrl: variant.imageUrl,
    imageAlt: variant.imageAlt,
    stars: variant.stars,
    name: variant.productName,
    urlPath: variant.productUrl,
  }))

  const addToCart = async () => {
    loading.value = true
    try {
      const cartItems: CartItem[] = props.variants.map(({ value }) => ({
        variant_id: `${value}`,
        quantity: 1,
      }))
      await useCartStore().addToCart(cartItems)
    } catch (error) {
      handleError(error)
    } finally {
      loading.value = false
    }
  }
</script>
